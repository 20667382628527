.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.review_head {
  height: 90px;
  display: flex;
  justify-content: space-between;
  margin: 0 240px;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 600;
  color: #333;
}
.review_head .left,
.review_head .right {
  cursor: pointer;
}
.review_head .left a,
.review_head .right a {
  text-decoration: none;
  color: #333;
}
.review_head .right {
  font-weight: 400;
}
.review_head .right:hover {
  border-bottom: 3px solid #ff6600;
}
.about-wrapper .banner-container {
  position: relative;
  height: 35.41666666666667vw;
}
.about-wrapper .banner-container .title-box {
  position: absolute;
  z-index: 2;
  top: 12.968750000000002vw;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.about-wrapper .banner-container .title-box .top {
  margin-bottom: 0.6770833333333334vw;
  line-height: 5.833333333333333vw;
  font-size: 4.166666666666666vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.about-wrapper .banner-container .title-box .bottom {
  width: 47.239583333333336vw;
  margin: 0 auto;
  height: 1.7187500000000002vw;
}
.about-wrapper .banner-container .title-box .bottom .title {
  width: 100%;
}
.about-wrapper .banner-container .banner-item {
  width: 100vw;
  height: 35.41666666666667vw;
}
.about-wrapper .banner-container .banner-item .main {
  width: 100%;
}
.about-wrapper .banner-container .swiper-pagination {
  position: absolute;
  bottom: 3.125vw;
  left: 50%;
  transform: translateX(-50%);
}
.about-wrapper .banner-container .swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 1.25vw;
  height: 0.3125vw;
  border-radius: 0.20833333333333334vw;
  background-color: #fff;
  margin-right: 0.4166666666666667vw;
}
.about-wrapper .banner-container .swiper-pagination /deep/ .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.about-wrapper .banner-container .swiper-pagination /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ff6600;
}
.about-wrapper .text-intro {
  box-sizing: border-box;
  height: 40.416666666666664vw;
  padding-top: 4.739583333333333vw;
}
.about-wrapper .text-intro > .title {
  margin-bottom: 2.65625vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.about-wrapper .text-intro .content-box {
  display: flex;
}
.about-wrapper .text-intro .content-box .info {
  margin-left: 12.5vw;
  padding-top: 0.8854166666666666vw;
}
.about-wrapper .text-intro .content-box .info .company-title {
  position: relative;
  margin-bottom: 1.40625vw;
  line-height: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.about-wrapper .text-intro .content-box .info .company-title::after {
  content: "";
  position: absolute;
  bottom: -0.6770833333333334vw;
  left: 0;
  width: 1.4583333333333333vw;
  height: 0.3125vw;
  background-color: #ff6600;
}
.about-wrapper .text-intro .content-box .info .desc {
  margin: 0;
  width: 27.083333333333332vw;
  margin-bottom: 1em;
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.about-wrapper .text-intro .content-box .info .desc:last-of-type {
  margin-bottom: 2.604166666666667vw;
}
.about-wrapper .text-intro .content-box .info .subtitle {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.6666666666666667vw;
}
.about-wrapper .text-intro .content-box .info .subtitle .text {
  margin-right: 0.3125vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.about-wrapper .text-intro .content-box .info .subtitle .content {
  width: 27.083333333333332vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.about-wrapper .text-intro .content-box .building {
  width: 57.291666666666664vw;
  height: 30.416666666666664vw;
}
.about-wrapper .video-intro {
  box-sizing: border-box;
  height: 58.802083333333336vw;
}
.about-wrapper .video-intro .banner-box {
  box-sizing: border-box;
  height: 35.41666666666667vw;
  padding-top: 4.947916666666666vw;
  background: url(https://mktv-in-cdn.mockuai.com/16273731699099359.png) top left / cover no-repeat, linear-gradient(270deg, #001554 0%, #00092a 100%);
}
.about-wrapper .video-intro .banner-box > .title {
  margin-bottom: 3.854166666666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.about-wrapper .video-intro .banner-box .video-box {
  position: relative;
  width: 75vw;
  height: 42.1875vw;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
  margin: 0 auto;
}
.about-wrapper .video-intro .banner-box .video-box .video {
  width: 100%;
  height: 100%;
}
.about-wrapper .video-intro .banner-box .video-box .poster {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.about-wrapper .video-intro .banner-box .video-box .poster .main {
  width: 100%;
  height: 100;
}
.about-wrapper .video-intro .banner-box .video-box .poster .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.791666666666667vw;
  height: 4.791666666666667vw;
}
.about-wrapper .management-container {
  box-sizing: border-box;
  height: 44.166666666666664vw;
  padding-top: 4.791666666666667vw;
  background: url(https://mktv-in-cdn.mockuai.com/16273757598925055.png) 0 0 / cover no-repeat, linear-gradient(270deg, #001554 0%, #00092a 100%);
}
.about-wrapper .management-container > .title {
  margin-bottom: 4.479166666666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.about-wrapper .management-container .cards-row {
  display: flex;
  align-items: center;
  width: 75vw;
  margin: 0 auto;
}
.about-wrapper .management-container .cards-row .card-item {
  height: 7.291666666666667vw;
  margin-right: 2.083333333333333vw;
  margin-bottom: 2.083333333333333vw;
}
.about-wrapper .management-container .cards-row .card-item .main {
  height: 100%;
}
.about-wrapper .founder-container {
  box-sizing: border-box;
  height: 47.552083333333336vw;
  padding-top: 4.739583333333333vw;
}
.about-wrapper .founder-container > .title {
  margin-bottom: 3.75vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.about-wrapper .founder-container .cards {
  display: flex;
  align-items: center;
  width: 75vw;
  margin: 0 auto;
}
.about-wrapper .founder-container .cards .card-item {
  position: relative;
  width: 22.916666666666664vw;
  height: 31.25vw;
  margin-right: 3.125vw;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
}
.about-wrapper .founder-container .cards .card-item:last-child {
  margin-right: 0;
}
.about-wrapper .founder-container .cards .card-item .main {
  width: 100%;
}
.about-wrapper .founder-container .cards .card-item .info {
  visibility: visible;
  position: absolute;
  left: 2.03125vw;
  bottom: 1.9270833333333335vw;
}
.about-wrapper .founder-container .cards .card-item .info .name {
  margin-bottom: 0.5208333333333333vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.about-wrapper .founder-container .cards .card-item .info .post {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.about-wrapper .founder-container .cards .card-item .desc {
  display: none;
  margin-top: 0.625vw;
  width: 18.75vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}
.about-wrapper .founder-container .cards .card-item:hover .desc {
  display: block;
}
.about-wrapper .develop-container {
  position: relative;
  box-sizing: border-box;
  height: 87.44791666666667vw;
  background-color: #fff;
}
.about-wrapper .develop-container .develop-box {
  position: relative;
  box-sizing: border-box;
  height: 69.16666666666667vw;
  padding-top: 4.6875vw;
  background: url(https://mktv-in-cdn.mockuai.com/16273793646096852.png) top left / cover no-repeat, linear-gradient(270deg, #001554 0%, #00092a 100%);
}
.about-wrapper .develop-container .develop-box > .title {
  margin-bottom: 4.479166666666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.about-wrapper .develop-container .develop-box .content-box {
  display: flex;
}
.about-wrapper .develop-container .develop-box .content-box .main {
  width: 50vw;
  height: 33.489583333333336vw;
  margin-right: 6.25vw;
}
.about-wrapper .develop-container .develop-box .content-box .timeline {
  position: relative;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item {
  position: relative;
  display: flex;
  padding-bottom: 2.5vw;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item::before {
  content: "";
  position: absolute;
  top: 0.20833333333333334vw;
  width: 1.5104166666666667vw;
  height: 1.1979166666666667vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16273796532383646.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item::after {
  content: "";
  position: absolute;
  left: 0.5729166666666666vw;
  top: 1.40625vw;
  height: calc(100% - 23 * 0.05208333vw);
  width: 0.10416666666666667vw;
  background-color: #fff;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item:last-child {
  padding-bottom: 0;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item:last-child::after {
  content: "";
  width: 0;
  height: 0;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item .year {
  margin-left: 3.802083333333333vw;
  margin-right: 2.083333333333333vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item .info {
  padding-top: 0.20833333333333334vw;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item .info .title {
  margin-bottom: 0.625vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.about-wrapper .develop-container .develop-box .content-box .timeline .time-item .info .items .item {
  margin-bottom: 0.625vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
}
.about-wrapper .develop-container .honor-box {
  position: absolute;
  width: 100%;
  bottom: -14.479166666666668vw;
}
.about-wrapper .develop-container .honor-box > .title {
  margin-bottom: 4.166666666666666vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.about-wrapper .develop-container .honor-box .swiper {
  position: relative;
  width: 75vw;
  margin: 0 auto;
}
.about-wrapper .develop-container .honor-box .swiper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.2916666666666665vw;
  height: 2.2916666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.about-wrapper .develop-container .honor-box .swiper .swiper-arrow.prev {
  left: -2.2916666666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274920200113393.png);
}
.about-wrapper .develop-container .honor-box .swiper .swiper-arrow.next {
  right: -2.2916666666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274920199899246.png);
}
.about-wrapper .develop-container .honor-box .swiper .honor-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.1875vw;
  height: 24.270833333333332vw;
  background-color: #fff;
}
.about-wrapper .develop-container .honor-box .swiper .honor-item .main {
  width: 100%;
}
